var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-12 col-lg-12 order-lg-2 order-xl-1"},[_c('KTPortlet',{class:'kt-portlet--height-fluid',scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('div',{staticClass:"basic-table"},[_c('div',{staticClass:"row basic-table__header no-gutters align-items-center"},[_c('div',{staticClass:"col-4"},[_c('h3',{staticClass:"basic-table__title text-seizeorange"},[_vm._v(" 잔액 집계 ")])])]),_c('div',{staticClass:"basic-table__table"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-4"},[_c('v-data-table',{staticClass:"seize-table-box",attrs:{"headers":_vm.inputSumHeader,"items":_vm.inputSumData,"dense":"","hide-default-footer":"","item-key":"key","loading-text":"데이터 조회중입니다..."},scopedSlots:_vm._u([{key:"item.key",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"color":"#000000","font-weight":"bold"}},[_vm._v(_vm._s(item.key))])]}},{key:"item.count",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"color":"#000000"}},[_vm._v(_vm._s(item.count))])]}},{key:"item.sum",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"color":"#000000"}},[_vm._v(_vm._s(item.sum))])]}}])})],1),_c('div',{staticClass:"col-8"},[_c('Chart',_vm._b({ref:"balanceChart"},'Chart',{
                      options: _vm.chartOptions,
                    },false))],1)])])])]},proxy:true}])})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-12 col-lg-12 order-lg-2 order-xl-1"},[_c('KTPortlet',{class:'kt-portlet--height-fluid',scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('TransactionDataTable',_vm._b({ref:"balanceInputTable"},'TransactionDataTable',{
              baseDate: _vm.baseDate,
              titleText: '잔액종가 0원 상세',
              titleTextClass: 'text-seizeorange',
              detailData: _vm.detailInputDataArray,
              filterDisabled: {
                term: false,
                inout: true,
                targetInst: true,
                name: true,
                level2: true,
                level3: true,
                level4: true,
                level5: true,
                tranType: true,
              },
              inoutInitialFilter: ['입금', '출금'],
              itemsPerPage: 10,
            },false))]},proxy:true}])})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }